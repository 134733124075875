//
// responsive.scss
//

@media (min-width: 200px) and (max-width: 768px) {

    .navbar-custom {
        margin-top: 0px;
        padding: 15px 0px !important;
        background-color: $white !important;
        box-shadow: 0 10px 33px rgba(0, 0, 0, .1);
        color: $white !important;

        .navbar-nav {
            margin-top: 0px;
           
            li{
                &.active{
                    border-color: transparent;
                }
                a {
                    transition: all 0.4s;
                    color: $dark !important;
                    margin: 0px;
                }
            }
        }
        >.container{
            width: 90%;
            
        }
    }
  
    .navbar-custom .navbar-nav li.active a,
    .navbar-custom .navbar-nav li a:hover,
    .navbar-custom .navbar-nav li a:active {
        color: $primary !important;
    }
  
   
    .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: $dark;
    }
    

    .home-content{
        .title{
            font-size: 44px;
        }
    }

    .logo{
        .logo-light {
            display: inline-block;
            content:url("../images/logo-dark.png");
          
           
        }
        .logo-dark {
            display: inline-block;
            
        }

        
      

        
    } 

    .progress-border{
        &:before{
            border: none;  
        }
      
    }
    
    // hide mailchimp form
    #subscribe
        {
            display:none;
        } 
  

        //hide slider booking form
      #slider-booking-form
      {
          margin-top: 50px;
          width: 320px;
         
          .title{
              width:320px;
              font-size: 16px;
              
          }
      }
      
      #home
      {
          margin-top:120px;
      }

     
     
}




@media(max-width: 425px) {
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        display: none; 
    }

    .carousel-indicators {
        li {
            width: 50px;
            height: 50px;
        }
    }

}
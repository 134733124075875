//Import Bootstrap
@import '../node_modules/bootstrap/scss/bootstrap.scss';

//Import Icons
@import './assets/scss/style.scss';
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.categories{
    color: inherit;
}

.comingsoon {
    height: 100vh;
    overflow: hidden;
  
}
.comingsoon img.logo {
  
}

.comingsoon .main {
    display: grid;
    grid-template-columns: 35% 35%;
    gap: 14%;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    position: relative;
    height: 100vh;
}
.comingsoon .rgt img.phone01 {
    width: 100%;
}
.comingsoon .main h1 {
    font-family: "Lobster", cursive;
    font-size: 6vw;
    margin-bottom: 2vw;
    position: relative;
}

.comingsoon .main p{
    animation: ctype  steps(41) 3s infinite;
    overflow: hidden;
    white-space: nowrap;
  
    width: 100%;
    
  }
  
  @keyframes ctype {
    0% {
      width: 0%;
      opacity: 1;
    }
    100% {
      width: 100%;
      opacity: 0.7;
    }
  }
.comingsoon .main h1:after {
    content: "";
    background: url(./assets/images/round.png) center;
    width: 200px;
    height: 200px;
    position: absolute;
    left: -96px;
    margin: 0 auto;
    top: 19px;
    background-size: cover;
    z-index: -1;
    opacity: 0.4;
}

.comingsoon .main h2 {
    font-family: "Merriweather Sans", sans-serif;
    font-size: 3vw;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 1vw;
    letter-spacing: 2px;
}
.comingsoon .main h2 span {
    font-size: 3vw;
}
.comingsoon .button {
    position: relative;
    margin-bottom: 20px;
}

.comingsoon .button input {
    width: 100%;
    border-radius: 100px;
    border: 1px solid #ccc;
    padding: 16px 40px;
    font-weight: 500;
    transition: all .3s ease,background 0s,color 0s;
    letter-spacing: 0.5px;
    font-size: 0.8vw;
}

.comingsoon .button button {
    background: #343a40;
    color: #ffffff;
    position: absolute;
    right: 7px;
    top: 7px;
    padding: 10px 40px;
    border-radius: 100px;
    font-family: "Lobster", cursive;
    letter-spacing: 1px;
    border: 0;
    transition: all 0.35s linear;
    font-size: 0.8vw;
}
.comingsoon .button button:hover {
    background: #ffb000;
}
.comingsoon .lft p {
    font-family: "Merriweather Sans", sans-serif;
    font-size: 0.8vw;
    font-weight: 300;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

.comingsoon .rgt {
    position: relative;
  
}

.comingsoon img.phone010 {
    position: absolute;
    left: -192px;
    width: 65%;
    top: 129px;
    opacity: 0.1;
}

.comingsoon img.phone011 {
    position: absolute;
    left: -110px;
    width: 86%;
    top: 52px;
    opacity: 0.8;
}

.comingsoon img.phone020 {
    position: absolute;
    right: -110px;
    width: 86%;
    top: 52px;
    z-index: 1;
    opacity: 0.8;
}

.comingsoon img.phone021 {
    position: absolute;
    right: -192px;
    width: 65%;
    top: 129px;
    opacity: 0.1;
}


.comingsoon .rgt:after {
    content: "";
    background: url(./assets/images/round.png) center;
    width: 113%;
    height: 130%;
    position: absolute;
    right: 0px;
    left: -75px;
    z-index: -1;
    top: -21px;
    background-size: cover;
}
.comingsoon:after {
    content: "";
    background: url(./assets/images/bottem.png) center;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0px;
    height: 176px;
    opacity: 0.9;
    background-size: cover;
}
.comingsoon:before {
    content: "";
    background: url(./assets/images/bottem.png) center;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: -4px;
    height: 164px;
    transform: rotate(180deg);
    background-size: cover;
    opacity: 0.7;
}
.comingsoon .button input:focus {
    outline: none;
    padding-left: 50px;
    transition: all .3s ease,background 0s,color 0s;
}
.footer-social a {
    color: rgb(52 58 64);
    font-size: 0.8vw;
    transition: all 0.5s;
    border: 1px solid #838383;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}
.footer-social a:hover {
    color: #ffab00;
    border: 1px solid #ffab00;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.9rem;
}
.comingsoon .main:before {
    content: "";
    background: url(./assets/images/giphy.webp) center;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
}
img.logo {
    width: 24%;
}
.footer-social {
    position: relative;
    z-index: 9999999999;
}
@media only screen and (max-width: 480px) {

    .comingsoon .main {
        grid-template-columns: 100%;
        gap: 1%;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px 20px;
        height: 100%;
    }
    img.logo {
        width: 55%;
        margin-bottom: 6vw;
    }
    .comingsoon .main h1 {
   
        font-size: 12vw;
       
    }
    .comingsoon .main h2 {
        margin-bottom: 6vw;
        font-size: 8vw;
      
    }
    .comingsoon .main h2 span {
        font-size: 6vw;
    }

    .comingsoon .button input {
        padding: 16px 20px;
        font-size: 12px;
    }

    .comingsoon .button button {
        padding: 8px 30px;
        font-size: 14px;
    }
    .footer-social a {
    
        font-size: 16px;
        
    }
    .comingsoon .lft p {
 
        font-size: 12px;
     
    }
    .comingsoon {
        height: 100%;
      
    }
    .comingsoon .main h1:after{display: none;}

}